export default {
  data: function data() {
    return {
      accountCode: '',
      loginCompanyName: '',
      historyList: [],
      total: 2,
      current: 1,
      // 页码
      perPage: 5,
      rangeBefore: 3,
      rangeAfter: 1
    };
  },
  created: function created() {
    this.getHistoryList();
  },
  filters: {
    statusName: function statusName(type) {
      var statusObj = {
        0: "提现发起",
        1: "提现处理中",
        2: "提现已完结"
      };
      return statusObj[type];
    }
  },
  methods: {
    handleChange: function handleChange(val) {
      this.current = val;
      this.getHistoryList();
    },
    getHistoryList: function getHistoryList() {
      var _this = this;
      var url = '/pjyy-deepexi-user-center/api/v1/balance/getWithdrawalPageList';
      var params = {
        page: this.current,
        pageSize: this.perPage
      };
      this.$getHttpClient().get(url, params).then(function (res) {
        if (res.data.code == '200') {
          _this.historyList = res.data.data.list;
          _this.total = res.data.data.total;
          _this.perPage = res.data.data.pageSize;
        }
      });
    }
  }
};